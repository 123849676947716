import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useMenus, Link } from "docz";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./style-index.css";
export const CardsPack = () => {
  const sections = useMenus();
  sections.sort(function (a, b) {
    if (a.name > b.name) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }

    return 0;
  });
  return <>
      <style type="text/css">
        {`.btn-cardbutton{ background-color:rgb(31, 121, 191); color:white; } .btn-cardbutton:hover{ background-color:rgb(52, 140, 209); color:white; }`}
      </style>
      <Container style={{
      minWidth: "1000px",
      marginLeft: "-90px"
    }} mdxType="Container">
        <h1 className="text-center mb-4">
          Welcome to TeamCoaches Documentation
        </h1>
        <Row mdxType="Row">
          {sections.map((section, index) => !section.hidden && !!section.menu && <Col md={6} className="mb-4" key={section.id} mdxType="Col">
                  <Card className="h-100 shadow-card" mdxType="Card">
                    <Card.Body>
                      <Card.Title>
                        <h3>{section.name}</h3>
                      </Card.Title>
                      <Card.Text style={{
                fontSize: 15
              }}>
                        {section.menu[0] && section.menu[0].description}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{
              backgroundColor: "white",
              border: 0
            }}>
                      <Button as={Link} to={`/${section.name.toLowerCase()}`} variant="cardbutton" mdxType="Button">
                        Go to {section.name} docs
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>)}
        </Row>
      </Container>
    </>;
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  CardsPack,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <CardsPack mdxType="CardsPack" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      